import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./providers/auth";
import { SessionProvider } from "./providers/session";
import { DialogProvider } from "./components/common/appDialog";
import { ErrorBoundary } from "./components/errorBoundary";

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<div></div>}>
      <ErrorBoundary>
        <SessionProvider>
          <AuthProvider>
            <DialogProvider>
              <App />
            </DialogProvider>
          </AuthProvider>
        </SessionProvider>
      </ErrorBoundary>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
